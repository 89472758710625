import baseTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui"
import { merge } from "theme-ui"
import { tailwind } from "@theme-ui/presets"

export default merge(baseTheme, {
  initialColorModeName: `dark`,
  colors: {
    primary: `#1e6bd6`,
    modes: {
      dark: {
        text: tailwind.colors.white,
        primary: `#5f9ff5`,
        secondary: `#7f8ea3`,
        background: `#1D2229`,
      },
    },
  },
  fonts: {
    body: `"IBM Plex Sans", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  },
  layout: {
    container: {
      padding: [3, 4],
      maxWidth: `920px`,
    },
  },
  styles: {
    p: {
      marginBottom: `35px`,
    },
  },
})